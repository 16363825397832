import {ClientModel, ContractStatus} from '../step-components/select-clients/data/client.model';
import {AssetModel} from '../../new-asset/data/asset.model';
import {PartnerType, ReferralType} from '../step-components/select-contract-type/data/select-contract-type.types';
import {UserModel} from 'src/app/models/user.model';

export class ICreateNewContractRequest {
	id: string;
	company_id: string;
	groupContractId?: string;
	createDate: Date;
	contractType: ContractType;
	assets: AssetModel[];
	clients: ClientModel[];
	userId: string;
	buyCommissionType: CommissionType;
	buyCommissionPrice: number;
	rentCommissionType: CommissionType;
	rentCommissionPrice: number;
	notes: string;
	sendBySMS: boolean;
	sendByEmail: boolean;
	sendByWhatsapp: boolean;
	hideAssetAddress: boolean;
	status: ContractStatus;
	contractNumber?: string;
	fromExclusiveDate: Date | null;
	toExclusiveDate: Date | null;
	exclusiveContractRefId?: string | null;
	partnerType: PartnerType | null;
	referralType: ReferralType[] | null;
	enabled: boolean;
}

export class GetContractResponseModel extends ICreateNewContractRequest {
	signedDate: Date;
	tokenId: string;
	_id: string;
	__v: number;
	fileKey?: string;

	user?: UserModel;

	constructor(init?: Partial<GetContractResponseModel>) {
		super();
		if (init) {
			Object.assign(this, init);
			if (init.userId) {
				this.user = init.userId as unknown as UserModel;
			}
		}
	}
}

export enum ContractType {
	Seller = 'Seller', //מוכר
	Buyer = 'Buyer', //קונה
	Renter = 'Renter', //משכיר
	Tenant = 'Tenant', //שוכר
	Partner = 'Partner', //שותף
	SellerAndRenter = 'SellerAndRenter', //מוכר ומשכיר
	BuyerAndTenant = 'BuyerAndTenant', //קונה ושוכר
	SellerExclusivity = 'SellerExclusivity', //בלעדיות מוכר
	Referral = 'Referral', //הפניה
}

export enum CommissionType {
	PERCENTAGE = 'PERCENTAGE',
	FIXED = 'FIXED',
	MONTH_RENT = 'MONTH_RENT',
}

export enum SendByType {
	SMS = 'SMS',
	EMAIL = 'Email',
	WhatsApp = 'WhatsApp',
}

export interface IExclusiveRangeDate {
	from: Date;
	to: Date;
}

export function translateContractType(type: ContractType): ContractTypeTranslation {
	const hebrewTypes: Record<ContractType, ContractTypeTranslation> = {
		[ContractType.Seller]: ContractTypeTranslation.Seller,
		[ContractType.Buyer]: ContractTypeTranslation.Buyer,
		[ContractType.Renter]: ContractTypeTranslation.Renter,
		[ContractType.Tenant]: ContractTypeTranslation.Tenant,
		[ContractType.Partner]: ContractTypeTranslation.Partner,
		[ContractType.SellerAndRenter]: ContractTypeTranslation.SellerAndRenter,
		[ContractType.BuyerAndTenant]: ContractTypeTranslation.BuyerAndTenant,
		[ContractType.SellerExclusivity]: ContractTypeTranslation.SellerExclusivity,
		[ContractType.Referral]: ContractTypeTranslation.Referral,
	};

	return hebrewTypes[type];
}

export enum ContractTypeTranslation {
	Seller = 'מוכר',
	Buyer = 'קונה',
	Renter = 'משכיר',
	Tenant = 'שוכר',
	Partner = 'שותף',
	SellerAndRenter = 'מוכר ומשכיר',
	BuyerAndTenant = 'קונה ושוכר',
	SellerExclusivity = 'בלעדיות מוכר',
	Referral = 'הפניה',
}
