import {Injectable, WritableSignal, signal} from '@angular/core';
import {Observable, map, of, switchMap, tap} from 'rxjs';
import {IUserResponse, UserModel} from '../models/user.model';
import {HttpClient} from '@angular/common/http';
import {GlobalApi} from '../core/global.url';
import {IUpdateUser} from '../components/user-profile/data/user-profile.types';
import {AuthService} from './auth.service';
import {ISignUpRequest, ISignUpResponse} from '../components/clients copy/sub-components/user-form/data/sign-up.types';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	public allUsers: WritableSignal<UserModel[]> = signal([]);

	constructor(private http: HttpClient, private authService: AuthService) {}

	getAll(includeDisabledUsers: boolean, force: boolean = false): Observable<UserModel[]> {
		const companyId = JSON.parse(localStorage.getItem('company')!)._id;
		if (!force && this.allUsers().length > 0) {
			return of(this.allUsers());
		}
		if (!companyId) {
			return of([]);
		}
		let url = '';
		if (includeDisabledUsers) {
			url = `${GlobalApi.url}/users`;
		} else {
			url = `${GlobalApi.url}/users/company/${companyId}`;
		}
		return this.http.get<IUserResponse[]>(url).pipe(
			map((users: IUserResponse[]) => {
				const usersList = users.map(user => new UserModel(user));
				this.allUsers.set(usersList);
				return usersList;
			}),
		);
	}

	getUserById(id: string): Observable<UserModel> {
		const url = `${GlobalApi.url}/users/${id}`;
		return this.http.get<IUserResponse>(url).pipe(
			map((user: IUserResponse) => {
				return new UserModel(user);
			}),
		);
	}

	getUserSecretKey(id: string): Observable<string> {
		const url = `${GlobalApi.url}/users/secret-key/${id}`;
		return this.http.get<{secretKey: string}>(url).pipe(
			switchMap(res => {
				if (res.secretKey) {
					return of(res.secretKey);
				} else {
					return this.generateSecretKey(id);
				}
			}),
		);
	}

	generateSecretKey(id: string): Observable<string> {
		const url = `${GlobalApi.url}/users/generate-secret-key/${id}`;
		return this.http.get<{secretKey: string}>(url).pipe(map(res => res.secretKey));
	}

	getUserSlug(id: string): Observable<string> {
		const url = `${GlobalApi.url}/users/public-url/${id}`;
		return this.http.get<{publicUrl: string}>(url).pipe(map(res => res.publicUrl));
	}

	update(user: IUpdateUser, me: boolean = false): Observable<UserModel> {
		const url = `${GlobalApi.url}/users/update/${user.id}`;
		return this.http.patch<IUserResponse>(url, user).pipe(
			map((user: IUserResponse) => {
				const updatedUser = new UserModel(user);
				//update user localstorage
				if (updatedUser && me) {
					this.authService.setUserData(updatedUser);
					localStorage.setItem('user', JSON.stringify(updatedUser));
				}
				return updatedUser;
			}),
		);
	}

	toggleEnabledUser(id: string, enabled: boolean): Observable<UserModel> {
		const url = `${GlobalApi.url}/users/toggle-enabled/${id}`;
		const body = {enabled};
		return this.http.patch<IUserResponse>(url, body).pipe(
			map((user: IUserResponse) => {
				const updatedUser = new UserModel(user);
				return updatedUser;
			}),
		);
	}

	signUp(addUser: ISignUpRequest): Observable<UserModel> {
		return this.http.post<ISignUpResponse>(`${GlobalApi.url}/auth/signup`, addUser).pipe(
			map((user: ISignUpResponse) => {
				return new UserModel(user);
			}),
		);
	}

	moveDataToNewUser(fromUserId: string, toUserId: string): Observable<boolean> {
		const url = `${GlobalApi.url}/users/move-data`;
		const body = {fromUserId, toUserId};
		return this.http.patch<boolean>(url, body).pipe(map(res => (res ? true : false)));
	}
}
