import {ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, catchError, map, of, switchMap} from 'rxjs';
import {AppInjector} from 'src/app/app.component';
import {AuthGuardActivation} from 'src/app/guards/auth.guard';
import {SignService} from './service/sign.service';
import {ContractStatus} from '../create-contract/step-components/select-clients/data/client.model';
import {ContractType, GetContractResponseModel} from '../create-contract/data/contact.types';
import {ToastService} from '../global/snackbar/toast.service';

export const SignGuardActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
	return AuthGuardActivation(route, state).pipe(
		switchMap((isLoggedIn: boolean) => {
			if (!isLoggedIn) return of(false);

			const router = AppInjector.get(Router);
			const signService = AppInjector.get(SignService);
			const toastService = AppInjector.get(ToastService);
			const routeId: any = route.params; //contractId / groupContractId

			return signService.getContractByOriginalId(routeId.id).pipe(
				catchError(err => {
					if (err.status === 403 && err.error && err.error.message === 'expired token') {
						router.navigate(['/expired-contract']);
						return of(null);
					}
					console.error('Error fetching contracts:', err);
					toastService.open({type: 'error', message: 'לא מצאנו הסכם מתאים'});
					router.navigate(['home']);
					throw err;
				}),
				//if there is just 1 contract with this groupContractId, then test if it is WAIT_TO_SIGN
				//if there are multiple contracts with this groupContractId, then go to select-signer
				map((contracts: null | GetContractResponseModel[]) => {
					if (!contracts) return false;
					signService.contractsData.set(contracts);
					switch (contracts.length) {
						case 0:
							return false;
						case 1:
							const contract = contracts[0];
							switch (contract.status) {
								case ContractStatus.WAIT_TO_SIGN:
									signService.contractId.set(contract._id);
									signService.currentContract.set(contract);
									if (contract.groupContractId) {
										signService.groupContractId.set(contract.groupContractId);
									}
									if (contract.exclusiveContractRefId && contract.contractType === ContractType.SellerExclusivity) {
										signService.exclusiveContractId.set(contract.exclusiveContractRefId);
									}
									return true;
								case ContractStatus.CANCELED:
								case ContractStatus.REJECTED:
									return false;
								case ContractStatus.COMPLETED:
									if (routeId.id) {
										router.navigate(['/already-sign', routeId.id]);
									}
									return false;
								case ContractStatus.EXPIRED:
									router.navigate(['/expired-contract']);
									return false;
								default:
									toastService.open({type: 'error', message: 'ההסכם לא נמצא במצב נכון לחתימה'});
									router.navigate(['home']);
									return false;
							}
						default:
							router.navigate(['/select-signer', routeId.id]);
							return false;
					}
				}),
			);
			//   } else {
			//     return of(false);
			//   }
		}),
	);
};

export const SignGuardTempActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
	const token = route.params['token'];
	if (!token) return of(false);
	const signService = AppInjector.get(SignService);
	const router = AppInjector.get(Router);
	const toastService = AppInjector.get(ToastService);
	return signService.getContractByToken(token).pipe(
		catchError(err => {
			if (err.status === 403 && err.error === 'expired token.') {
				router.navigate(['/expired-contract']);
				return of(null);
			}
			console.error('Error fetching contracts:', err);
			toastService.open({type: 'error', message: 'לא מצאנו הסכם מתאים'});
			router.navigate(['home']);
			throw err;
		}),
		map(res => {
			//check is res is an array of contracts or a single contract
			const contracts = Array.isArray(res) ? res : [res];
			signService.contractsData.set(contracts);
			switch (contracts.length) {
				case 0:
					return false;
				case 1:
					const contract = contracts[0];
					switch (contract.status) {
						case ContractStatus.WAIT_TO_SIGN:
							signService.tokenId.set(token);
							signService.currentContract.set(contract);
							if (contract.exclusiveContractRefId) {
								signService.exclusiveContractId.set(contract.exclusiveContractRefId);
							}
							if (contract.groupContractId) {
								signService.signGroupContractId.set(contract.groupContractId);
							}
							return true;
						case ContractStatus.CANCELED:
						case ContractStatus.REJECTED:
							return false;
						case ContractStatus.COMPLETED:
							if (route.params['token']) {
								router.navigate(['/already-sign', route.params['token']]);
							}
							return false;
						default:
							toastService.open({type: 'error', message: 'ההסכם לא נמצא במצב נכון לחתימה'});
							router.navigate(['home']);
							return false;
					}
				case 2:
					if (contracts?.length > 1 && contracts[0]?.groupContractId) {
						const contract = contracts.every(c => c.status === ContractStatus.COMPLETED);
						if (contract) {
							router.navigate(['/already-sign', route.params['token']]);
							return false;
						}
						signService.tokenId.set(token);
						signService.currentContract.set(contracts[0]);
						signService.signGroupContractId.set(contracts[0].groupContractId);
					}
					return true;
				default:
					router.navigate(['/select-signer', route.params['token']]);
					return false;
			}
		}),
	);
};

export const SignGuardDeActivate: CanDeactivateFn<any> = (
	component: any,
	currentRoute: ActivatedRouteSnapshot,
	currentState: RouterStateSnapshot,
	nextState?: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	if (nextState?.url.startsWith('/sign')) {
		return false;
	}
	return true;
};
