import {UserRoles} from '../components/clients copy/data/user.types';
import {ISignUpResponse} from '../components/clients copy/sub-components/user-form/data/sign-up.types';

export class UserModel {
	id: string;
	email: string;
	fullname: string;
	phoneNumber: string;
	licenseNumber: string;
	enabled: boolean;
	company: string;
	companyId: string;
	role: UserRoles;
	createDate: Date;
	slug: string;

	displayName?: string = '';

	constructor(init: Partial<UserModel | ISignUpResponse>) {
		Object.assign(this, init);
		// if(this.licenseNumber){
		//     this.licenseNumber = this.licenseNumber;
		// }

		this.displayName = this.fullname || this.email || this.phoneNumber;
	}
}

export interface IShortUser {
	_id: string;
	fullname: string;
	email: string;
	phoneNumber: string;
}

export interface IUserResponse {
	id: string; // _id
	fullname: string;
	email: string;
	phoneNumber: string;
	licenseNumber: string;
	role: UserRoles;
	enabled: boolean;
	company_id: string;
	createDate: Date;
	slug: string;
}
